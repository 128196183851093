import * as React from "react";
import { getPublication } from "../utils/asyncServerRequests";
import { Link, useParams } from "react-router-dom";

import {
  RenderNavButton,
  RenderResultKey,
} from "../components/renderers/renderers";
import { isURL } from "../utils/checkIsUrl";

interface bookData {
  [type: string]: any;
}

const Book = () => {
  const [book, setBook] = React.useState<bookData>({});
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const path = useParams().slug;
  const author = useParams().author;
  const id = useParams().id;
  const populateBook = async (params: string, author: string, id: string) => {
    setLoading(true);
    const result = await getPublication(params, author, id);
    setBook(result);
    setLoading(false);
  };
  React.useEffect(() => {
    populateBook(path, author, id);
  }, [path, author, id]);

  // this function is responsible for handling italic text across book and article
  // const returnItalicText = (testString?: string, testForString?: string) => {
  //   if (testString && testForString && testString.includes(testForString)) {
  //     const [citationStart, citationEnd] = testString.split(testForString);
  //     return `${citationStart}<span style="font-style: italic">${testForString}</span>${citationEnd}`.replace(/\n/g, "<br>");
  //   }
  //   return (testString || "").replace(/\n/g, "<br>");
  // };

  const returnItalicText = (testString = "", testForString = "") => {
    // Scott, Niall. "The Monstrous Metallic in Medicine and Horror Cinema." Medicina Nei Secoli Arte e Scienza (Journal of History of Medicine), vol. 26, no. 1, 2014, pp. 313–32.
    // Durgnat, Raymond. A Long Hard Look at 'Psycho.' 2002. 2nd ed., British Film Institute, 2010.

    // Medicina Nei Secoli Arte e Scienza (Journal of History of Medicine)
    // A Long Hard Look at 'Psycho'

    if (testString && testForString) {
      // Escape special characters in the testForString
      const escapedTestForString = testForString.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );
      const regex = new RegExp(`(${escapedTestForString})`, "g");
      return testString
        .replace(regex, '<span style="font-style: italic">$1</span>')
        .replace(/\n/g, "<br>");
    }
    return testString.replace(/\n/g, "<br>");
  };

  return (
    <main className="mx-auto mt-8 space-y-6 lg:mt-24 wrapper">
      {!isLoading && (
        <>
          <section className="grid grid-cols-12 gap-8">
            <div className="flex flex-col col-span-12 space-y-2 lg:col-span-9">
              <h1 className="text-xl italic font-bold text-white lg:text-3xl">
                {book.title}
              </h1>
              <div className="flex flex-col space-y-2 lg:items-center lg:space-y-0 lg:space-x-8 lg:flex-row">
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13.684 23.25H1.824a1.077 1.077 0 01-1.078-1.076V1.826A1.077 1.077 0 011.824.75h15.094A1.077 1.077 0 0118 1.826v5.381M7.203 6.375h6.456"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3.805 6a.375.375 0 11-.375.375A.375.375 0 013.805 6M3.805 9.861a.375.375 0 11-.375.375.375.375 0 01.375-.375M15.929 11.352a3.864 3.864 0 00-.511-.307l-1.182-.591a2.071 2.071 0 00-2.129.167l-1.845 1.318A2.609 2.609 0 009.2 13.691l-.279 1.958M23.246 22.5l-5.355-8.564"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13.707 16.5l5.571-3.413a1.614 1.614 0 10-1.687-2.753L7.5 16.519l-1.911 3.063 3.6-.311L11.246 18"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16.5 16.5h-3.75a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5h2.742l1.758 3.75"
                    />
                  </svg>
                  <p className="font-medium text-gray-300 lg:text-lg">
                    {book.author}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4.5 5.523v15a2.25 2.25 0 002.25 2.25 2.207 2.207 0 00.392-.039l9.406-1.682a3.584 3.584 0 002.952-3.528v-10.5a1.256 1.256 0 00-1.476-1.236L7.147 7.738a2.25 2.25 0 11-.794-4.429l11.619-2.082M16.37 1.514l2.142 4.283"
                    />
                  </svg>
                  <p className="font-medium text-gray-300 lg:text-lg">
                    {book.isArticle ? "Article" : "Book"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-3 lg:justify-self-end">
              <RenderNavButton path="/search" text="Revise Search" />
            </div>
          </section>
          <section className="px-6 py-10 rounded shadow-md bg-blackLight">
            {/* <!-- Top Half--> */}
            <div className="grid grid-cols-12 gap-9">
              <div className="grid grid-flow-row col-span-8 space-y-6 auto-rows-max">
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                  {book.alternativeTitle && (
                    <div className="space-y-1">
                      <RenderResultKey text="Alternative Title" />
                      <p className="text-lg font-medium text-gray-300">
                        {book.alternativeTitle}
                      </p>
                    </div>
                  )}

                  {book.isbn13 && (
                    <div className="space-y-1">
                      <RenderResultKey text="ISBN-13" />
                      {book.isbn13?.map((num: string) => (
                        <p className="text-lg font-medium text-gray-300">
                          {num}
                        </p>
                      ))}
                    </div>
                  )}

                  {book.publication && (
                    <div className="space-y-1">
                      <RenderResultKey text="Publication" />
                      <p className="text-lg font-medium text-gray-300">
                        {book.publication}
                      </p>
                    </div>
                  )}

                  <div className="space-y-1">
                    <RenderResultKey text="Year" />
                    <p className="text-lg font-medium text-gray-300">
                      {book.year}
                    </p>
                  </div>

                  {book.originalPublisher && (
                    <div className="space-y-1">
                      <RenderResultKey text="Original Publisher" />
                      <p className="text-lg font-medium text-gray-300">
                        {book.originalPublisher}
                      </p>
                    </div>
                  )}

                  {book.updatedEdition && (
                    <div className="space-y-1">
                      <RenderResultKey text="Updated Edition" />
                      <p className="text-lg font-medium text-gray-300">
                        {book.updatedEdition}
                      </p>
                    </div>
                  )}

                  {book.mla8Citation && (
                    <div className="space-y-1">
                      <RenderResultKey text="MLA 8 Citation" />
                      <p
                        className="text-lg font-medium text-gray-300"
                        dangerouslySetInnerHTML={{
                          __html: returnItalicText(
                            book.mla8Citation,
                            book.publication || book.title
                          ),
                        }}
                      ></p>
                    </div>
                  )}

                  {book.doiStableUrl && (
                    <div className="space-y-1">
                      <RenderResultKey text="DOI/Stable URL" />
                      {isURL(book.doiStableUrl) && (
                        <a
                          href={book.doiStableUrl}
                          target="_blank"
                          rel="noopener"
                          className="text-lg font-medium text-primaryOrange"
                        >
                          {book.doiStableUrl}
                        </a>
                      )}

                      {!isURL(book.doiStableUrl) && (
                        <p className="text-lg font-medium text-gray-800">
                          {book.doiStableUrl}
                        </p>
                      )}
                    </div>
                  )}

                  {book.publisherStableUrl && (
                    <div className="space-y-1">
                      <RenderResultKey text="Publisher/Stable URL" />
                      {isURL(book.publisherStableUrl) && (
                        <a
                          href={book.publisherStableUrl}
                          target="_blank"
                          rel="noopener"
                          className="text-lg font-medium text-primaryOrange"
                        >
                          {book.publisherStableUrl}
                        </a>
                      )}

                      {!isURL(book.publisherStableUrl) && (
                        <p className="text-lg font-medium text-gray-800">
                          {book.publisherStableUrl}
                        </p>
                      )}
                    </div>
                  )}

                  <div className="space-y-1">
                    <RenderResultKey text="Free Online Copy" />
                    <ul>
                      {!book.freeOnlineCopy && (
                        <p className="text-lg font-medium text-gray-800">
                          Not available
                        </p>
                      )}

                      {book.freeOnlineCopy?.map((each: string) => {
                        return (
                          <li>
                            {isURL(each) ? (
                              <a
                                href={each}
                                target="_blank"
                                rel="noopener"
                                className="text-lg font-medium underline text-primaryOrange hover:text-primaryOrangeDark"
                              >
                                Online copy available here
                              </a>
                            ) : (
                              <p className="text-lg font-medium text-gray-800">
                                {each}
                              </p>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {book.reprintedIn && (
                    <div className="space-y-1">
                      <RenderResultKey text="Reprinted In" />
                      <p
                        className="text-lg font-medium text-gray-300"
                        dangerouslySetInnerHTML={{
                          __html: returnItalicText(
                            book.reprintedIn,
                            book.isArticle ? book.publication : book.title
                          ),
                        }}
                      ></p>
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden col-span-3 lg:block">
                <img
                  // src={`https://horror-lex.nyc3.cdn.digitaloceanspaces.com/books/${book.photo}.jpg`}
                  src={book.s3ImageURL}
                  alt={book.title}
                  className="mx-auto"
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="space-y-2">
                <RenderResultKey text="For More Information" />
                {!book.isArticle && (
                  <div className="flex flex-col justify-start mt-2 space-y-4 lg:mt-0 lg:space-x-8 lg:space-y-0 lg:flex-row">
                    {book.googleBooks && (
                      <a
                        href={book.googleBooks}
                        target="_blank"
                        rel="noopener"
                        className="inline-block"
                      >
                        <img
                          src="/img/logo/gb.png"
                          alt="Google Books logo"
                          className="h-10"
                        />
                      </a>
                    )}

                    {book.goodReads && (
                      <a
                        href={book.goodReads}
                        target="_blank"
                        rel="noopener"
                        className="inline-block"
                      >
                        <img
                          src="/img/logo/gr.png"
                          alt="Good Reads Logo"
                          className="h-10"
                        />
                      </a>
                    )}

                    {book.worldCat && (
                      <a
                        href={book.worldCat}
                        target="_blank"
                        rel="noopener"
                        className="inline-block"
                      >
                        <img
                          src="/img/logo/wc.png"
                          alt="World Cat Logo"
                          className="h-10"
                        />
                      </a>
                    )}
                  </div>
                )}

                {book.isArticle && (
                  <div className="flex items-center justify-start space-x-8">
                    {book.muse && (
                      <a
                        href={book.muse}
                        target="_blank"
                        rel="noopener"
                        className="inline-block"
                      >
                        <img
                          src="/img/logo/muse.png"
                          alt="Muse Logo"
                          className="w-40"
                        />
                      </a>
                    )}

                    {!book.muse && (
                      <p className="text-lg font-medium text-gray-800">
                        Muse - Not available
                      </p>
                    )}

                    {/* {{/if}} */}
                    {/* {{#if article.jstor}} */}
                    {book.jstor && (
                      <a
                        href={book.jstor}
                        target="_blank"
                        rel="noopener"
                        className="inline-block"
                      >
                        <img
                          src="/img/logo/jstor.png"
                          alt="JSTOR Logo"
                          className="w-40"
                        />
                      </a>
                    )}

                    {!book.jstor && (
                      <p className="text-lg font-medium text-gray-800">
                        JSTOR - Not available
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <hr className="my-16" />
            {/* <!-- Bottom Half--> */}
            <div className="space-y-8">
              <div className="max-w-3xl space-y-2">
                {book.recommended && (
                  <div className="space-y-2">
                    <RenderResultKey text="Horror Lex Recommends ✅" />
                  </div>
                )}
                <RenderResultKey text="Horror Lex Summary" />
                <div
                  className="text-lg text-gray-300"
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof book?.summary === "string"
                        ? `<p>${book.summary.replace(/\n/g, "<br>")}</p>`
                        : "",
                  }}
                ></div>
              </div>
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
                <div className="col-span-1 space-y-2 lg:col-span-5">
                  <RenderResultKey text="Films Discussed" />
                  {!book.films && (
                    <p className="text-lg font-medium text-gray-800">
                      Not available
                    </p>
                  )}
                  {book.films?.map((each: string) => {
                    let dotFreeString = each.replace(/・/g, "");
                    return (
                      <p className="text-base font-normal text-gray-400">
                        <Link
                          to={`/results?count=10&page=1&query=${dotFreeString}&sort=title&type=film`}
                          className="block"
                        >
                          {each}
                        </Link>
                      </p>
                    );
                  })}
                </div>
                <div className="col-span-1 space-y-2 lg:col-span-7">
                  <RenderResultKey text="Tags" />
                  {!book.tags && (
                    <p className="text-lg font-medium text-gray-800">
                      Not available
                    </p>
                  )}
                  {book.tags?.map((each: string) => {
                    return (
                      <a
                        href={`/results?count=10&page=1&query=${each}&sort=title&type=tag`}
                        className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800 m-0.5"
                      >
                        {each}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  );
};

export default Book;
